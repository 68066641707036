import classes from "./App.module.css";
import { useEffect, useRef, useState } from "react";
import Container from "./Components/Container/Container";
import Button from "@mui/material/Button"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { IoIosPlayCircle } from "react-icons/io";
import { PiVideoCameraFill } from "react-icons/pi";
import Marquee from "react-fast-marquee";
import QA from "./Components/QA/QA";
import useScreenWidth from "./Hooks/useScreenWidth"

import Aos from "aos"
import "aos/dist/aos.css"

import logo1 from "./Assets/logo1.png"
import logo2 from "./Assets/logo2.png"
import logo3 from "./Assets/logo3.png"
import logo4 from "./Assets/logo4.png"
import logo5 from "./Assets/logo5.png"
import logo6 from "./Assets/logo6.png"
import logo7 from "./Assets/logo7.png"
import logo8 from "./Assets/logo8.png"
import dilya from "./Assets/dilya.webp"
import moon from "./Assets/moon.webp"
import earth from "./Assets/earth.webp"
import vinnik from "./Assets/vinnik.jpg"
import patoka from "./Assets/patoka.jpg"
import nadi from "./Assets/nadi.jpg"
import alina from "./Assets/alina.jpg"
import shilina from "./Assets/shilina.jpg"
import programImg from "./Assets/program.png"
import stars from "./Assets/stars.png"
import tg from "./Assets/tg.png"
import insta from "./Assets/insta.png"
import { TbWorldWww } from "react-icons/tb";
import RecordModal from "./Components/RecordModal/RecordModal";
import PrerecordModal from "./Components/RecordModal/PrerecordModal";
import vidos from "./Assets/daria_low.mp4"
import vidos2 from "./Assets/vidos_low.mp4"
import vidos3 from "./Assets/vidos2_low.mp4"
import vidos4 from "./Assets/vidos3_low.mp4"
import Preloader from "./Components/Preloader/Preloader";
import BrandModal from "./Components/BrandModal/BrandModal";

const App = () => {
  const [selectedCity, setSelectedCity] = useState("Будапешт")
  const [preorder, setPreorder] = useState(null)

  const [isFetching, setIsFetching] = useState(false)

  const [selectedTarif, setSelectedTarif] = useState(false)

  const [currentTeachers, setCurrentTeachers] = useState([])

  const [currentBrand, setCurrentBrand] = useState(null)

  const [onlineProgram, setOnlineProgram] = useState([])

  const buyRef = useRef(null)

  const scrollToBuy = () => {
    buyRef.current.scrollIntoView({
      behavior: "smooth"
    })
  }

  const teachers = [
    {
      photo: vinnik,
      name: "Дар'я Вінник",
      exp: "6 РОКІВ",
      students: 100,
      lessons: 20,
      video: vidos,
      text: `Всім привіт! Мене звати Дарʼя Вінник.Ми з командою ALIEVA BEAUTY ставимо ціль розвивати майстрів та давати вам передові знання та ділитись з вами своїм досвідом та напрацюваннями.
   
Мій шлях майстра почався у 2018 роціу місті Мелітополь, Запорізької області та я завжди прагнула з перших днів роботи рости професійно та розвиватись.
Я стала технологом компанії Алієва Бьюті у 2021 році, на той час в мене вже була студія , та я почала будувати свою команду, це навчання на технолога дало мені великий стрибок у професіональному плані, я дуже зросла, дівчина з маленького міста, я викликала фурор у своєму місті, до мене у студію на процедури була черга на 2-3 місяці вперед, я почала навчати, до нас приїздили дівчата з інших областей України. 
В мене були величезні плани, я планувала відкривати ще одну студію, набирати ще одну команду майстрів, але… у лютому 2022 року все змінилось. Перевернулось все життя, всі плани тепер були нейздісненими, та в квітні я кидаю все, що напраювала титанічним трудом. 
Я з сімєю переїжджаю до Дніпра, де починаю все з нуля, знову напрацювання клієнтської бази, я почала з того, що знімала по одному дню крісло в супер некомфортній для роботи студіі, потім я вийшла на те, що вже були напрацьовані клієнти, почались записи та у липні (через 2 місяці) я ризикую та знімаю крісло на два тижня у місяць, працюю дуже багато над розвитком, просто 24/7.
Та мрію про власну студію, на момент приїзду до Дніпра це здавалось чимось нездійсненим. Але я говорила собі, чоловіку, що до Нового року я відкрию свою студію, це була моя ціль.
Через півроку після приізду – у листопаді в блек аут)) я відкриваю свою студію у центрі Дніпра, та відразу беру на роботу помічницю, ще одного майстра. В мене не було тоді так багато клієнтів, щоб завантажити роботою ще одного майстра, але вже була відповідальність перед людиною,тому я почала працювати ще більше. До нас почали йти більше та більше дівчат, почали приїзджати на навчання з усієї Украіни, почались онлайн навчання у Європі. 

         Зараз я маю особистий бренд , проводжу безліч навчань по всьому світу, проводжу семінари , вебінари , випускаю топ майстрів .
      `
    },
    {
      photo: patoka,
      name: "Марія Патока",
      exp: "2 РОКИ",
      students: 40,
      lessons: 15,
      video: vidos3,
      text: `Навчаю швидкому зростанню майстрів.
За 2 роки у професії навчила понад 40 учениць з нуля та на підвищеннях індивідуально, зробивши з них успішних майстрів та професіоналів своєї справи.
Провела понад 15 майстер класів не лише Україною, а й у турі Європою.
Відкрила свою студію у місті Дніпро через 1 рік роботи, а зараз розширила її межі.
Будує команду всередині студії з працюючим майстром, якому напрацювала додаткову базу клієнтів.
Проводить навчання як онлайн, так і офлайн, вчить виходити на новий рівень швидко і легко.
      `
    },
    {
      photo: nadi,
      name: "Надь Ніколетта",
      exp: "3 РОКИ",
      students: 100,
      lessons: 20,
      video: vidos2,
      text: `Своєю мотивацією та підтримкою навчила професіоналів своєї справи у яких карʼєрний ріст швидко розвивається!

Відкрила 2 салони краси в столиці Угорщини - 
м. Будапешт , які активно працюють та знайомлять іноземців  з новими процедурами . 
Збудувала баланс між бізнесом  та життям.
Вчить якісно та швидко - а саме головне вчить будувати цілі та фінансову грамотність в бізнесі  - робити з неможливого МОЖЛИВЕ!
      `
    },
    {
      photo: alina,
      name: "Аліна Палеєва",
      exp: "10 РОКІВ",
      students: 100,
      lessons: 50,
      text: `Майстер модифікації та реконструкції волосся з досвідом  10 років 

- понад 5000 клієнтів
- понад 100 учнів по всьому світу (ЄВРОПА/УКРАЇНА/США)
- технолог компанії Alieva Beauty більше 4х років
- два повноцінних салона краси у Європі
        
Готова поділитись і розкрити всі свої секрети ідеального полотна для твоїх клієнтів
      `
    },
    {
      photo: shilina,
      name: "Олександра Шиліна",
      exp: "2 РОКИ",
      students: 10,
      lessons: 5,
      video: vidos,
      text: `У 17 років я купила своє перше навчання і зрозуміла, що це робота моєї мрії
        
Кинула університет і почала самостійно вивчати тему реконструкції та відпрацьовувати на моделях 
        
Маю більше 400 задоволених клієнтів, особисті техніки та лайфхаки в перший рік роботи 
        
Після 10 місяців роботи запустила свій базовий курс та в перший же місяць випустила 2 дівчини які з 0 почали робити ідеальні роботи і візуали 
        
Я наймолодший технолог в Україні з реконструкції волосся і:
- Навчу тебе заробляти великі гроші в перші місяці роботи з задоволенням і кайфом;
- Розкрию тобі всю базу про волосся і процедури, нюанси фінансової грамотності, продажів та таргету;
- Де шукати клієнтів;
- І як закрити кейс обслуговування
      `
    }
  ]

  const brands = [
    {
      img: logo3,
      text: "Український бренд професійної косметики для волосся, що співпрацює з бразильськими виробниками для створення засобів, спеціально адаптованих під український ринок. Сладники безпечні та сертифіковані в Україні! Косметика поділяється на 2 типи: професійна та домашній догляд."
    },
    {
      img: logo4,
      text: `Унікальність цього бренду полягає у високотехнологічних розробках у напрямках естетики та реконструкції волосся. Бренд включає більше 30-ти напрямів але особливий акцент зроблений на африканський тип волосся тому що батьківщина бренду ʼYBERA PARIS' - сонячна Бразилія.
Косметика поділяється на 2 типи: професійний та домашній догляд.
      `
    },
    {
      img: logo5,
      text: `У лінійку бренду входять безліч продуктів починаючи від реконструкції та закінчуючи модифікацією волосся. Виробник врахував усі вимоги майстрів до продуктів з реконструкції волосся та розробив максимально ефективні догляди для різних напрямків та вимог. Такий ретельний підхід до створення продуктів допомагає майстрам досягати високих результатів та надавати клієнтам якісні послуги.

Косметика поділяється на 2 типи: професійний та домашній догляд.
      `
    },
    {
      img: logo6,
      text: `Провідний бренд кератинів і ботоксу для волосся, спеціально створений у Бразилії для українських майстринь. Продукція бренду визнана топовою на ринку завдяки високій якості та ефективності
Кератини та ботокси є безпечними та сертифікованими складниками в Україні
      `
    },
    {
      img: logo7,
      text: ``
    },
    {
      img: logo8,
      text: `Косметика створюється на базі турецького заводу виробника, що е гарантом якості та ефективності.
В основі  всіх продуктів лежать низькомолекулярні амінокислоти та протеїни, які є будівельним матеріалом волосся та необхідні для реконструкції та відновлення. 
Використовується тільки якісна інноваційна
та запатентована сировина, яка гарантує результат для клієнтів.
Результатом використання косметики ANIDEM є повернення волоссю еластичності, шовковистості та природної структури.
Косметика поділяється на 2 типи: професійний та домашній догляд.
      `
    }
  ]

  const tarify = [
    {
      title: "Онлайн",
      price: "5 000",
    },
    {
      title: "офлайн",
      price: "10 000",
    },
    {
      title: "Онлайн + офлайн",
      price: "15 000",
    },
  ]

  const qa = [
    {
      q: "Чому ми виділяємо учениць із натовпу?",
      a: "Ми підбираємо тих, хто справді прагне результатів, а не просто грошей. Це спільнота для тих, хто бачить свою місію у вдосконаленні своїх навичок та бізнесу. Люди з такою мотивацією автоматично залучають успіх, включаючи фінансовий. Ми виділяємо тих, хто готовий вкладатися у свою справу та працювати на максимальний результат."
    },
    {
      q: "️Місія ALIEVA Beauty",
      a: "Місія ALIEVA Beauty – створювати професіоналів, які не просто працюють заради грошей, а й удосконалюють себе та свою індустрію. Ми формуємо нову спільноту лідерів, які прагнуть вищої якості у всьому, що роблять, і стають прикладом для інших."
    },
    {
      q: "️Як і ким ми бачимо учасників клубу, наші цінності",
      a: `Ми бачимо учасників як цілеспрямованих, енергійних людей, які готові працювати на результат. У клубі закладаються такі цінності, як:
      - Постійний розвиток та зростання.
      - Підтримка один одного та взаємодопомога.
      - Готовність працювати над собою та досягати амбітних цілей.
      - Чесність, працьовитість та прагнення до досконалості.
      
      Це клуб для тих, хто живе своєю професією та бачить успіх не лише в грошах, а й у результатах, які вони приносять своїй справі та клієнтам.`
    }
  ]

  const screenWidth = useScreenWidth()

  useEffect(() => {
    switch (selectedCity) {
      case "Будапешт": {
        setCurrentTeachers([teachers.find(el => el.name === "Олександра Шиліна"), teachers.find(el => el.name === "Надь Ніколетта")])
        break
      }
      case "Дніпро": {
        setCurrentTeachers([teachers.find(el => el.name === "Дар'я Вінник")])
        break
      }
      case "Київ": {
        setCurrentTeachers([teachers.find(el => el.name === "Марія Патока")])
        break
      }
      case "Краків": {
        setCurrentTeachers([teachers.find(el => el.name === "Аліна Палеєва")])
        break
      }
      case "Варшава": {
        setCurrentTeachers([teachers.find(el => el.name === "Аліна Палеєва")])
        break
      }
    }
  }, [selectedCity])

  useEffect(() => {
    if (screenWidth > 1279) {
      setOnlineProgram([
        {
          title: "Теорія",
          week: 1,
          lessons: [
            "1 / Будова волосся та шкіри голови",
            "2 / Волосся: види, структури, особливості волосся",
            "3 / Косметична хімія",
            "4 / Промисловість реконструкції та модифікації: процедури",
            "5 / Склади, огляд інструментів, аксесуарів і всього всього",
            "6 / ТБ, правила роботи, витяжка, світло, додаткові вкладення, стартовий набір, як побудувати прайс, стратегія роботи, з чим стартуємо і тд",
            "7 / Іспит та доступ до наступного блоку"
          ],
          videoCount: "05",
          videoText: 'Відеоуроки',
          zoomCount: "02",
          zoomText: "Zoom-сесії"
        },
        {
          title: "Особливості роботи",
          week: 3,
          lessons: [
            "1 / Домашній догляд",
            "2 / Правила роботи",
            "3 / Складні/поодинокі випадки",
            "4 / Лайфхакі, таблиці, шпаргалки",
            "5 / Мотивація/шлях",
            "6 / Мотивація/шлях",
            "7 / Мотивація/шлях Іспит і доступ до наступного блоку"
          ],
          videoCount: "06",
          videoText: 'Відеоуроків',
          zoomCount: "01",
          zoomText: "Zoom-сесія"
        },
        {
          title: "Процедури",
          week: 2,
          lessons: [
            "1 / Кератини: сильні",
            "2 / Кератини: середні",
            "3 /  Кератини: легкі",
            "4 / Ботокси: концентрати, рідкі",
            "5 / Нанопластика: перманент",
            "6 / Реконструкція: відновлення, підкладки",
            "7 / Додаткові процедури: окудріння, пілінг, стрижка кінчиків і так далі Іспит і доступ до наступного тижня "
          ],
          videoCount: "06",
          videoText: 'Відеоуроків',
          zoomCount: "01",
          zoomText: "Zoom-сесія"
        },
        {
          title: "Портфоліо",
          week: 4,
          lessons: [
            "1 /  Фінанси: вкладення на старті та протягом року: стратегія розвитку майстра на рік",
            "2 /  Упаковка профілю",
            "3 / Просування",
            "4 / Сервіс",
            "5 / Портфоліо та як знімати",
            "6 / Відповіді на запитання",
            "7 / Напуття, завершення, вручення дипломів, кінцевий іспит"
          ],
          videoCount: "07",
          videoText: 'Відеоуроки',
          zoomCount: "02",
          zoomText: "Zoom-сесії"
        },
      ])
    } else {
      setOnlineProgram([
        {
          title: "Теорія",
          week: 1,
          lessons: [
            "1 / Будова волосся та шкіри голови",
            "2 / Волосся: види, структури, особливості волосся",
            "3 / Косметична хімія",
            "4 / Промисловість реконструкції та модифікації: процедури",
            "5 / Склади, огляд інструментів, аксесуарів і всього всього",
            "6 / ТБ, правила роботи, витяжка, світло, додаткові вкладення, стартовий набір, як побудувати прайс, стратегія роботи, з чим стартуємо і тд",
            "7 / Іспит та доступ до наступного блоку"
          ],
          videoCount: "05",
          videoText: 'Відеоуроки',
          zoomCount: "02",
          zoomText: "Zoom-сесії"
        },
        {
          title: "Процедури",
          week: 2,
          lessons: [
            "1 / Кератини: сильні",
            "2 / Кератини: середні",
            "3 /  Кератини: легкі",
            "4 / Ботокси: концентрати, рідкі",
            "5 / Нанопластика: перманент",
            "6 / Реконструкція: відновлення, підкладки",
            "7 / Додаткові процедури: окудріння, пілінг, стрижка кінчиків і так далі Іспит і доступ до наступного тижня "
          ],
          videoCount: "06",
          videoText: 'Відеоуроків',
          zoomCount: "01",
          zoomText: "Zoom-сесія"
        },
        {
          title: "Особливості роботи",
          week: 3,
          lessons: [
            "1 / Домашній догляд",
            "2 / Правила роботи",
            "3 / Складні/поодинокі випадки",
            "4 / Лайфхакі, таблиці, шпаргалки",
            "5 / Мотивація/шлях",
            "6 / Мотивація/шлях",
            "7 / Мотивація/шлях Іспит і доступ до наступного блоку"
          ],
          videoCount: "06",
          videoText: 'Відеоуроків',
          zoomCount: "01",
          zoomText: "Zoom-сесія"
        },
        {
          title: "Портфоліо",
          week: 4,
          lessons: [
            "1 /  Фінанси: вкладення на старті та протягом року: стратегія розвитку майстра на рік",
            "2 /  Упаковка профілю",
            "3 / Просування",
            "4 / Сервіс",
            "5 / Портфоліо та як знімати",
            "6 / Відповіді на запитання",
            "7 / Напуття, завершення, вручення дипломів, кінцевий іспит"
          ],
          videoCount: "07",
          videoText: 'Відеоуроки',
          zoomCount: "02",
          zoomText: "Zoom-сесії"
        },
      ])
    }
  }, [screenWidth])

  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])

  return (
    <div className={classes.main}>
      {isFetching && <Preloader/>}
      {currentBrand && (
        <BrandModal text={currentBrand} onClose={() => setCurrentBrand(null)}/>
      )}
      {selectedTarif && (
        <RecordModal
          onClose={() => setSelectedTarif(null)}
          selectedTarif={selectedTarif}
          setIsFetching={setIsFetching}
        />
      )}
      {preorder && (
        <PrerecordModal
          onClose={() => setPreorder(null)}
          preorder={preorder}
          setIsFetching={setIsFetching}
        />
      )}
      {/* HOME */}
      <div className={classes.home}>
        <Container className={classes.homeContainer}>
          <h1 data-aos="fade-down" data-aos-duration="1500">COSMO</h1>
          <h2 data-aos="fade-down" data-aos-duration="1500">Вибух нових зірок.<br/>Інноваційні гарячі процедури для волосся</h2>
          <div className={classes.homeFull} data-aos="fade-down" data-aos-duration="1500"> 
            <p className={classes.homeFullText}>Унікальне навчання гарячим процедурам для волосся</p>
            <div className={classes.homeLogos}>
              <img src={logo2} alt="alieva"/>
              <p>&amp;</p>
              <img src={logo1} alt="store"/>
            </div>
          </div>
        </Container>
        <div className={classes.homeCities} data-aos="fade-up" data-aos-duration="1500">
          <Container className={classes.citiesContainer}>
            <div className={classes.field}>
              <div className={classes.innerField}>
                <div className={classes.col}>
                  <p className={classes.label}>Оберіть місто</p>
                  <div className={classes.selectors}>
                    <div className={classes.block}>
                      <p>24 жовтня</p>
                      <Button onClick={() => setSelectedCity("Будапешт")} className={`${classes.cityButt} ${selectedCity === "Будапешт" ? classes.activeButt : ""}`}>Будапешт</Button>
                    </div>
                    <div className={`${classes.block} ${classes.zhovt27}`}>
                      <p>27 жовтня</p>
                      <Button onClick={() => setSelectedCity("Варшава")} className={`${classes.cityButt} ${selectedCity === "Варшава" ? classes.activeButt : ""}`}>Варшава</Button>
                    </div>
                  </div>
                </div>
                <div className={classes.col}>
                  <p className={classes.label}>Передзапис</p>
                  <div className={classes.selectors}>
                    <div className={classes.pre}>
                      <p onClick={() => setPreorder("Львів")}>Львів,</p>
                      <p onClick={() => setPreorder("Одеса")}>Одеса,</p>
                      <p onClick={() => setPreorder("Харків")}>Харків</p>
                    </div>
                    <div className={classes.pre}>
                      <p onClick={() => setPreorder("Бухарест")}>Бухарест</p>
                      <br/>
                      <p onClick={() => setPreorder("Батумі")}>Батумі</p>
                    </div>
                    <div className={classes.pre}>
                      <p onClick={() => setPreorder("Валенсія")}>Валенсія</p>
                      <br/>
                      <p onClick={() => setPreorder("Кишинів")}>Кишинів</p>
                      <br/>
                      <p onClick={() => setPreorder("Дюссельдорф")}>Дюссельдорф</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.selectors}>
              <div className={classes.block}>
                <p>25 жовтня</p>
                <div className={classes.selectors}>
                  <Button onClick={() => setSelectedCity("Дніпро")} className={`${classes.cityButt} ${selectedCity === "Дніпро" ? classes.activeButt : ""}`}>Дніпро</Button>
                  <Button onClick={() => setSelectedCity("Київ")} className={`${classes.cityButt} ${selectedCity === "Київ" ? classes.activeButt : ""}`}>Київ</Button>
                  <Button onClick={() => setSelectedCity("Краків")} className={`${classes.cityButt} ${selectedCity === "Краків" ? classes.activeButt : ""}`}>Краків</Button>
                </div>
              </div>
            </div>
            <div className={`${classes.block} ${classes.zhovt27Mob}`}>
              <p>27 жовтня</p>
              <Button onClick={() => setSelectedCity("Варшава")} className={`${classes.cityButt} ${selectedCity === "Варшава" ? classes.activeButt : ""}`}>Варшава</Button>
            </div>
          </Container>
        </div>
      </div>
      {/* OFFLINE */}
      <div className={classes.offline}>
        <div className={classes.gradient}/>
        <Container className={classes.offlineContainer}>
          <h3 data-aos="fade-down" data-aos-duration="1500">Офлайн курс</h3>
          <div className={classes.speakers}>
            {currentTeachers.map(el => (
              <div className={classes.speaker} key={el.name}>
                <div data-aos="fade-down" data-aos-duration="1500" className={classes.photo}>
                  <img src={el.photo} alt={el.name}/>
                  <Button onClick={scrollToBuy}>записатись<br/>на курс</Button>
                </div>
                <div data-aos="fade-left" data-aos-duration="1500" className={classes.speakerInfo}>
                  <label>Спікер</label>
                  <p className={classes.speakerName}>{el.name}</p>
                  <p className={classes.speakerText}>Мотивую та навчаю талановитих майстрів.</p>
                  <div className={classes.speakerFeatures}>
                    <div className={classes.speakerFeature}>
                      <p>{el.exp}</p>
                    </div>
                    <div className={classes.speakerFeature}>
                      <p>{el.students}</p>
                      <p>УЧНІВ</p>
                    </div>
                    <div className={classes.speakerFeature}>
                      <p>{el.lessons}</p>
                      <p className={classes.small}>майстер класів</p>
                    </div>
                  </div>
                  <p className={classes.speakerText}>{el.text}</p>
                </div>
              </div>
            ))}
          </div>
          <div className={classes.programOffline} data-aos="fade-down" data-aos-duration="1500">
            <div className={classes.programInfo}>
              <div className={classes.programWeek}>
                <p>ОФЛАЙН</p>
                <p>1 ДЕНЬ</p>
              </div>
              <h4>25.10 - Практика</h4>
              <div className={classes.lessonsProgram}>
                <p>1 /  9:00-13:00 - Теорія</p>
                <p>2 /  11:30-12:00 - Перерва</p>
                <p>3 / 13:00-13:30 - Перерва</p>
                <p>4 / 13:30 - 16:30 - Модель гаряче</p>
                <p>5 / 17:00-20:30 - Модель гаряче</p>
                <p>6 / Вручення дипломів, фотосесія</p>
              </div>
              <p>*всі моделі з пілінгом, холодником, з показом техніки технолога</p>
            </div>  
            {currentTeachers.find(el => el.video) ? (
              <div className={classes.videos}>
                {currentTeachers.map(el => (
                  <video
                    // ref={videoRef}
                    controls
                  >
                    <source src={el.video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ))}
              </div>
            ) : (
              <img src={programImg} alt="program"/>
            )}
          </div>
          {/* BRANDS */}
        </Container>
        {/* ONLINE */}
      <div className={classes.online}>
        <img data-aos="fade" data-aos-duration="1500" src={moon} alt="moon" className={classes.moon}/>
        <Container className={classes.onlineContainer}>
          <h3 data-aos="fade-down" data-aos-duration="1500">Онлайн курс</h3>
          <div className={classes.onlineSpeaker}>
            <img data-aos="fade-down" data-aos-duration="1500" src={dilya} alt="діля алієва"/>
            <div data-aos="fade-left" data-aos-duration="1500" className={classes.onlineSpeakerInfo}>
              <p className={classes.onlineText}>Технолог онлайн блоку</p>
              <p className={classes.onlineName}>діля алієва</p>
              <p className={classes.onlineText}>
                Засновник компанії ALIEVA BEAUTY, почала 
                свій шляху сфері реконструкції волосся в 2017 році. 
                <br/><br/>
                На сьогоднішній день власниця компанії, засновник бренду ANIDEM , авторка багатьох методичних матеріалів, викладач з досвідом 5 років, яка навчила більше 500 майстрів зі всього світу та навчила і випустила багатьох технологів , яких ви знаєте.
              </p>
            </div>
          </div>
          <div className={classes.program} data-aos="fade-down" data-aos-duration="1500">
            <ImageList variant="masonry" cols={screenWidth > 1279 ? 2 : 1} gap={20}>
              {onlineProgram.map((item) => (
                <ImageListItem key={item.title}>
                  <div className={classes.programBlock}>
                    <div className={classes.programWeek}>
                      <p>ТИЖДЕНЬ</p>
                      <p>{item.week}</p>
                    </div>
                    <h4>{item.title}</h4>
                    <div className={classes.lessons}>
                      {item.lessons.map(el => (
                        <p key={el}>{el}</p>
                      ))}
                    </div>
                    <div className={classes.programCount}>
                      <div className={classes.feature}>
                        <IoIosPlayCircle/>
                        <strong>{item.videoCount}</strong>
                        <p>{item.videoText}</p>
                      </div>
                      <div className={classes.feature}>
                        <PiVideoCameraFill/>
                        <strong>{item.zoomCount}</strong>
                        <p>{item.zoomText}</p>
                      </div>
                    </div>
                  </div>
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        </Container>
        <img src={earth} alt="earth" className={classes.earth}/>
      </div>
        {/* TARIFY */}
        <Container className={classes.tarify}>
          <h3 data-aos="fade-down" data-aos-duration="1500" ref={buyRef}>Тарифи</h3>
          <div data-aos="fade-down" data-aos-duration="1500" className={classes.tarifyWrapper}>
            {tarify.map(el => (
              <div className={classes.tarif}>
                <p className={classes.tarifTitle}>{el.title}</p>
                <p className={classes.tarifPrice}>{el.price} <span>грн</span></p>
                <Button onClick={() => setSelectedTarif(el)}>Записатись на курс</Button>
              </div>
            ))}
          </div>
        </Container>
      </div>
      <div className={classes.vip}>
        <Container className={classes.vipContainer}>
          <h3 data-aos="fade-down" data-aos-duration="1500">VIP Клуб</h3>
          <div data-aos="fade-down" data-aos-duration="1500" className={classes.vipContent}>
            <p className={classes.vipText}>Після тарифів<br/>
              «Офлайн», «Офлайн + Онлайн» <br/>
              ви отримуєте статус VIP назавжди, який надає можливість закупівлі  DONATTI / YBERA / ANIDEM / TENDHAIR по знижці -20% від стандартного прайсу 
              <br/><br/>
              А також тільки для VIP клієнтів буде діяти можливість потрапити у закритий <strong>VIP CLUB </strong>
            </p>
            <div className={classes.vipFeatures}>
              <img src={stars} alt="stars"/>
              <p><strong>Привілеї учасників:</strong></p>
              <div className={classes.vipList}>
                <p>⭐ ️Доступ до закритої спільноти топових майстрів.</p>
                <p>⭐️ Участь у майстер-класах, де розбираються складні професійні теми.</p>
                <p>⭐️ Можливість зростання через колективне навчання та особисті зустрічі.</p>
                <p>⭐️ Участь у розіграшах цінних призів за дисципліну та досягнення.</p>
                <p>⭐️ Можливість подвоїти свій дохід та значно змінити своє мислення протягом року.</p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <h3 className={classes.partn}>Наші бренди</h3>
        <p className={classes.brandText}>Кожен наш продукт націлений на усунення певних проблем. Кожен бренд використовує тільки інноваційні технології та тільки ту сировину, яка максимально підходить природній структурі волосся. Бо саме тільки ті компоненти, з яких складається саме волосся, здатні реконструювати та відновлювати.
<br/><br/>Майстри та клієнти отримують продукти нового покоління, які здатні надавати якість та результат максимального рівня.</p>
      </Container>
      <Marquee className={classes.brands}>
        {brands.map(el => (
          <img src={el.img} alt="brand" onClick={() => setCurrentBrand(el.text)}/> 
        ))}
      </Marquee>
      <div data-aos="fade-down" data-aos-duration="1500" className={classes.qa}>
        <Container className={classes.qaContainer}>
          {qa.map((el, index) => (
            <QA item={el} index={index} key={el.a}/>
          ))}
        </Container>
      </div>
      <div data-aos="fade" data-aos-duration="1500" className={classes.footer}>
        <Container className={classes.footerContianer}>
          <p>COSMO</p>
          <div className={classes.links}>
            <a href="https://t.me/alieva_beauty" target="_blank">
              Telegram-канал
              <img src={tg} alt="tg"/>
            </a>
            <a href="https://www.instagram.com/alieva.beauty" target="_blank">
              Instagram
              <img src={insta} alt="insta"/>
            </a>
            <a href="https://alieva-beauty.com.ua/" target="_blank">
              alieva-beauty.com.ua
              <TbWorldWww/>
            </a>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default App;
