import Modal from "../Modal/Modal"
import classes from "./BrandModal.module.css"

const BrandModal = (props) => {
    const { onClose, text } = props

    return (
        <Modal onClose={onClose}>
            <div className={classes.container}>
                <p className={classes.main}>{text}</p>
            </div>
        </Modal>
    )
}

export default BrandModal