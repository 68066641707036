import { InputAdornment } from "@mui/material"
import TextField from "@mui/material/TextField"

const CustomInput = (props) => {
    const { 
        label, 
        type = "text", 
        onChange, 
        value, 
        error,
        multiline = false,
        rows = 1,
        regex = null,
        placeholder = "",
        disabled = false,
        dir = "ltr",
        inputRef,
        startAdornment = false,
        startAdornmentIcon = null,
    } = props

    const styles = {
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0140A9' 
        },
        '& .MuiOutlinedInput-root': {
            borderColor: '#0140A9',
            borderRadius: "50px",
            fontSize: "18px",
            height: "70px",
            paddingLeft: "10px",

            "@media screen and (max-width: 568px)": {
                height: "60px"
            }
        },
        '& .MuiFormHelperText-root.Mui-error': {
            margin: 0,
            marginTop: "10px",
            width: "fit-content",
            fontFamily: "Unbounded"
        },
        "& input": {
            fontSize: "16px",
            fontFamily: "Unbounded"
        }
    }

    const handleChange = (e) => {
        if(regex === "number") {
            const onlyNums = e.target.value.replace(/[^0-9]/g, '');
            onChange(onlyNums)
        } else {
            onChange(e.target.value)
        }
    }

    return (
        <TextField
            inputRef={inputRef}
            dir={dir}
            error={!!error}
            helperText={error ? error.message : null}
            sx={styles}
            label={label}
            variant={"outlined"}
            onChange={handleChange}
            placeholder={placeholder}
            value={value}
            autoComplete="off"
            type={type}
            disabled={disabled}
            multiline={multiline}
            rows={rows}
            InputProps={{
                startAdornment: startAdornment && (
                    <InputAdornment position='start' style={{ width: "fit-content", marginLeft: "10px", fontFamily: "Unbounded" }}>
                        {startAdornmentIcon}
                    </InputAdornment>
                )
            }}
        />
    )
}

export default CustomInput
