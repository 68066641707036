import IconButton from "@mui/material/IconButton"
import { useState } from "react";
import Collapse from '@mui/material/Collapse';
import classes from "./QA.module.css"
import { IoIosAdd } from "react-icons/io";

const QA = (props) => {
    const { item, index } = props

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div className={classes.main} style={{ borderTop: index === 0 ? "" : "1px solid rgba(255, 255, 255, .3)" }}>
            <div className={classes.header} onClick={handleExpandClick}>
                <p>{item.q}</p>
                <IconButton className={expanded ? classes.active : ""}>
                    <IoIosAdd/>
                </IconButton>
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <p className={classes.text}>{item.a}</p>
            </Collapse>
        </div>
    )
}

export default QA