import IconButton from "@mui/material/IconButton"
import classes from "./Modal.module.css"
import { IoClose } from "react-icons/io5";

const Modal = (props) => {
    const { onClose, children } = props

    return (
        <div className={classes.main}>
            <div className={classes.window}>
                <IconButton className={classes.close} onClick={onClose}>
                    <IoClose/>
                </IconButton>
                {children}
            </div>
        </div>
    )
}

export default Modal