import classes from './Container.module.css'

const Container = (props) => {
    const { className, children } = props

    return (
        <div className={`${classes.main} ${className}`}>
            {children}
        </div>
    )
}

export default Container