import Modal from "../Modal/Modal"
import { Controller, useForm } from "react-hook-form"
import CustomInput from "../CustomInput"
import { Button } from "@mui/material"
import classes from "./RecordModal.module.css"
import axios from "axios"
import { useState } from "react"

const RecordModal = (props) => {
    const { onClose, selectedTarif, setIsFetching } = props

    const { handleSubmit, reset, control } = useForm()

    const [isDone, setIsDone] = useState(false)

    const date = new Date();
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = `${date.toLocaleDateString('ru-RU', options)} ${date.toLocaleTimeString('ru-RU')}`;

    const token = '7887785924:AAECTmQwqoTTtcANAhm_ReMGzBKW5f_Shp8'; // Replace with your bot token
    const chatId = '-1002465069353'; // Replace with your chat ID

    const onSubmit = async (data) => {
        setIsFetching(true)
        const message = `НОВАЯ ЗАЯВКА __________

👱‍♀️ Имя: ${data.name};
☎️ Телефон: ${data.phone};
💵 Курс: ${selectedTarif.title}
📅 Дата: ${formattedDate}

____________________
        `

        try {
            const response = await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
                chat_id: chatId,
                text: message,
            });
            setIsDone(true)
        } catch (error) {
           console.log(error)
        } finally {
            setIsFetching(false)
        }
    }

    return (
        <Modal onClose={onClose}>
            <div className={classes.main}>
                <p>Записатись на курс "{selectedTarif.title}"</p>
                {isDone ? (
                    <p>Дякуємо! Ми успішно отримали вашу заявку. Ми незабаром з Вами зв'яжемось</p>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Обов'язкове поле!" }}
                            render={({
                                field: { onChange, value },
                                fieldState: { error }
                            }) => (
                                <CustomInput
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    placeholder="Ім'я"
                                />
                            )}
                        />
                        <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Обов'язкове поле!",
                            }}
                            render={({
                                field: { onChange, value },
                                fieldState: { error }
                            }) => (
                                <CustomInput
                                    onChange={onChange}
                                    value={value}
                                    regex={"number"}
                                    error={error}
                                    placeholder="Номер телефону"
                                />
                            )}
                        />
                        <Button type="submit">Записатись</Button>
                    </form>
                )}
            </div>
        </Modal>
    )
}

export default RecordModal